@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body, html{
  font-family: 'Poppins', sans-serif;
  font-weight:400;
}

input:focus,
textarea:focus,
select:focus{
  outline: 0 !important;
  box-shadow: none !important;
}

/* basic class for tailwind */
.linear-bg{
  background: linear-gradient(269.82deg, #07091c00 0.71%, #080a1ad6 99.87%)
}
.hoveres{
  @apply hidden;
}
.hovered:hover .hoveres{
  @apply flex-colo;;
}
.flex-colo{
  @apply flex flex-col justify-center items-center;
}
.flex-rows{
  @apply flex flex-row justify-center items-center;
}
.flex-btn{
  @apply flex justify-between items-center;
}
.transitions{
  @apply transition duration-300 ease-in;
}
.zIndex{
  z-index: 1000;
}

/* rc-drawer */

.rc-drawer{
  overflow: visible;
}
.rc-drawer .rc-drawer-content-wrapper{
  width: 100%;
}
.rc-drawer{
  position: fixed;
  z-index: 9999;
  transition: width 0s ease 0.3s, height 0s ease 0.3s,
  transform 0.3s cubic-bezier(0.78, 0.14 ,0.15, 0.86);
}
.rc-drawer>\*{
  transition: transform 0.3s cubic-bezier(0.78, 0.14 ,0.15, 0.86),
  opacity 0.3s cubic-bezier(0.78, 0.14 ,0.15, 0.86),
  box-shadow 0.3s cubic-bezier(0.78, 0.14 ,0.15, 0.86);
}

.rc-drawer.rc-drawer-open{
  transition: transform 0.3s cubic-bezier(0.78, 0.14 ,0.15, 0.86);
}
.rc-drawer .rc-drawer-mask{
  background: #000;
  opacity: 0;
  width: 100%;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s cubic-bezier(0.78, 0.14 ,0.15, 0.86),
  height 0s ease 0.3s;
}
.rc-drawer-content-wrapper{
  position: absolute;
  background: #fff;
}
.rc-drawer-content{
  overflow: auto;
  z-index: 1;
  position: relative;
}
.rc-drawer-handle{
  position: absolute;
  top: 72px;
  width: 41px;
  height: 40px;
  cursor: pointer;
  z-index: 0;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  display: none;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.rc-drawer-handle-icon{
  width: 14px;
  height: 2px;
  background: #333;
  position: relative;
  /* transition: background 0.3s cubic-bezier(0.78, 0.14 ,0.15, 0.86); */
  transition: transform 0.3s cubic-bezier(0.78, 0.14 ,0.15, 0.86);
}
.rc-drawer-handle-icon:before,
.rc-drawer-handle-icon:after{
  content: '';
  display: block;
  position: absolute;
  background: #333;
  width: 100%;
  height: 2px;
  transition: transform 0.3s cubic-bezier(0.78, 0.14 ,0.15, 0.86);
}
.rc-drawer-handle-icon:before{
  top: -5px;
}
.rc-drawer-handle-icon:after{
  top: 5px;
}
.rc-drawer-left,
.rc-drawer-right{
  width: 0%;
  height: 100%;
}
.rc-drawer-left .rc-drawer-content-wrapper,
.rc-drawer-right .rc-drawer-content-wrapper,
.rc-drawer-left .rc-drawer-content,
.rc-drawer-right .rc-drawer-content{
  height: 100%;
}
.rc-drawer-left.rc-drawer-open,
.rc-drawer-right.rc-drawer-open{
  width: 100%;
}
.rc-drawer-left.rc-drawer-open.no-mask,
.rc-drawer-right.rc-drawer-open.no-mask{
  width: 0%;
}
.rc-drawer-left{
  top: 0;
  left: 0;
}
.rc-drawer-left .rc-drawer-handle{
  right: -40px;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 4px 4px 0;
}
.rc-drawer-left.rc-drawer-open .rc-drawer-content-wrapper{
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}
.rc-drawer-right{
  top: 0;
  right: 0;
}
.rc-drawer-right .rc-drawer-content-wrapper{
  right: 0;
}
.rc-drawer-right .rc-drawer-handle{
  left: -40px;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px 0 0 4px;
}
.rc-drawer-right.rc-drawer-open .rc-drawer-content-wrapper{
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
}
.rc-drawer-right.rc-drawer-open.no-mask{
  right: 1px;
  transform: translateX(1px);
}
.rc-drawer-top,
.rc-drawer-bottom{
  width: 100%;
  height: 0%;
}
.rc-drawer-top .rc-drawer-content-wrapper,
.rc-drawer-bottom .rc-drawer-content-wrapper,
.rc-drawer-top .rc-drawer-content,
.rc-drawer-bottom .rc-drawer-content{
  width: 100%;
}
.rc-drawer-top .rc-drawer-content,
.rc-drawer-bottom .rc-drawer-content{
  height: 100%;
}
.rc-drawer-top.rc-drawer-open,
.rc-drawer-bottom.rc-drawer-open{
  height: 100%;
}
.rc-drawer-top.rc-drawer-open.no-mask,
.rc-drawer-bottom.rc-drawer-open.no-mask{
  height: 0%;
}
.rc-drawer-top .rc-drawer-handle,
.rc-drawer-bottom .rc-drawer-handle{
  left: 50%;
  margin-left: -20px;
}
.rc-drawer-top{
  top: 0;
  left: 0;
}
.rc-drawer-top .rc-drawer-handle{
  top: auto;
  bottom: -40px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 4px 4px;
}
.rc-drawer-top.rc-drawer-open .rc-drawer-content-wrapper{
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.rc-drawer-bottom{
  bottom: 0;
  left: 0;
}
.rc-drawer-bottom .rc-drawer-content-wrapper{
  bottom: 0;
}
.rc-drawer-bottom .rc-drawer-handle{
  top: -40px;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px 4px 0 0;
}
.rc-drawer-bottom.rc-drawer-open .rc-drawer-content-wrapper{
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
}
.rc-drawer-bottom.rc-drawer-open.no-mask{
  bottom: 1px;
  transform: translateY(1px);
}
.rc-drawer.rc-drawer-open .rc-drawer-mask{
  opacity: 0.3;
  height: 100%;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14 ,0.15, 0.86);
}
.rc-drawer.rc-drawer-open .rc-drawer-handle-icon{
  background: transparent;
}
.rc-drawer.rc-drawer-open .rc-drawer-handle-icon:before{
  transform: translateY(5px) rotate(45deg);
}
.rc-drawer.rc-drawer-open .rc-drawer-handle-icon:after{
  transform: translateY(-5px) rotate(-45deg);
}
@media (min-width: 480px){
  .rc-drawer .rc-drawer-content-wrapper{
    width: 420px;
  }
}




/* by default css of react */
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
